import React from 'react';
import {
  Form, Input, Button, Layout, Row, Col, Divider, notification, Typography
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import axios from 'axios';
import storePersist
 from '../utils/storePersist';
//import CryptoJS from 'crypto-js';
const { Title } = Typography;
const { Content } = Layout;

function Unsubsribe(props) {
  const url = props.url
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try{
      //const token = CryptoJS.SHA512(values.email).toString(CryptoJS.enc.Hex);
      const token = values.email;
      const loginToken = storePersist.get('token') ?? "";
      const response = await axios.get(url,{
          params: {
            token: token,
            login_token: loginToken
          },
          data: {},
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if(response.data.success === true){
        notification.success({
          message: response.data.message,
        });
        form.resetFields();
      }else{
        notification.error({
          message: response.data.message,
        });
      }
    }catch(error){
      notification.error({
        message: "Something went wrong!",
      });
    }
  };

  return (
      <Layout className="layout">
        <Row className='App-header'>
          <Col span={6} offset={18}>
            <Link to="/Logout" style={{fontSize: "20px", color: "red"}}>Logout</Link>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={6}>
            <Content
              style={{
                padding: '110px 0 180px',
                maxWidth: '360px',
                margin: '0 auto',
              }}
            >
              <Title level={2} style={{"textAlign": "center"}}>Email Unsubscribe</Title>
              <Divider />
              <div className="site-layout-content">
                {' '}
                <Form
                  name="unsubscribe_email"
                  form={form}
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input email!',
                      },
                      {
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Please enter correct email id format.'
                      }
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      autoComplete="off"
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item style={{"textAlign": "center"}}>
                    <Button
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Content>
          </Col>
        </Row>
      </Layout>
  );
}

export default Unsubsribe;