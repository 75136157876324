import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Login from './pages/login';
import Logout from './pages/logout';
import Unsubsribe from './pages/unsubscribe_email';
import { RequireAuth } from './utils/auth';
import { useState } from 'react';

function App() {
  const [url, setUrl] = useState();
  fetch('domain.json',{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      }
  }).then(function(response){
    return response.json();
  }).then(function(myJson) {
    setUrl(myJson.domain)
  });

  // setTimeout(()=> {
  //   setUrl("")
  // },500);

  return (
    <Router>
        <Routes>
          <Route path="/" element={
              <RequireAuth url={url}>
                <Unsubsribe url={url}/>
              </RequireAuth>
          }/>
          <Route path="/login" element={<Login url={url} />}/>
          <Route path="/logout" element={<Logout />}/>
        </Routes>
    </Router>
  );
}

export default App;
