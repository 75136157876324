import React, { useEffect } from 'react';
import {
  Form, Input, Button, Layout, Row, Col, Divider,notification, Typography
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import storePersist from '../utils/storePersist';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { checkAuth } from '../utils/auth';
const { Content } = Layout;
const { Title } = Typography;

function Login(props) {
  const url = props.url;
  const history = useNavigate();
  
  useEffect(()=>{
    const getAuth = async () => {
      const auth = await checkAuth(url);
      if(auth){
        history('/');
      }
    }
    getAuth();
  },[url,history]);
 
  const onFinish = async (values) => {
    const response = await axios.post(url+"/auth",
      {
        "username": values.username,
        "password": values.password,
      }
    );
    if(response.data.success === true){
      storePersist.set('token', response.data.token);
      history('/');
    }else{
      notification.error({
        message: `Invalid credentials!`,
      });
    }
  };

  return (
      <Layout>
        <Row>
          <Col span={12} offset={6}>
            <Content
              style={{
                padding: '110px 0 180px',
                maxWidth: '360px',
                margin: '0 auto',
              }}
            >
              <Title level={2} style={{"textAlign": "center"}}>Login</Title>
              <Divider />
              <div className="site-layout-content">
                {' '}
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      autoComplete="off"
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      autoComplete="off"
                      placeholder="Password"
                    />
                  </Form.Item>

                  <Form.Item style={{"textAlign": "center"}}>
                    <Button
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Content>
          </Col>
        </Row>
      </Layout>
  );
}

export default Login;