export const storePersist = {
    set: (key, state) => {
      window.localStorage.setItem(key, JSON.stringify(state));
    },
    get: (key) => {
      const result = window.localStorage.getItem(key);
      return JSON.parse(result);
    },
    remove: (key) => {
      window.localStorage.removeItem(key);
    },
    getAll: () => window.localStorage,
    clear: () => {
      window.localStorage.clear();
    },
  };
  
  export default storePersist;
  