import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import storePersist from './storePersist';
import axios from 'axios';
import {
  Layout, Row, Col, Typography
} from 'antd';
const { Content } = Layout;
const { Title } = Typography;

export function RequireAuth({children, url}) {
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
      const getAuth = async () => {
        const auth = await checkAuth(url);
     
        if(auth === false){
          history('/login');
        }else if(auth === true){
          setLoading(false);
        }
      }
      getAuth();
    },[url, history]);
    
    if (loading === true) {
      return <Layout>
        <Row>
          <Col span={12} offset={6}>
            <Content
              style={{
                padding: '110px 0 180px',
                maxWidth: '360px',
                margin: '0 auto',
              }}
            > <Title level={2} style={{"textAlign": "center"}}>Loading...</Title>
            </Content>
            </Col>
          </Row>
      </Layout>;
    }

    return children;
}

export async function checkAuth(url) {
  try{
    if(url){
      const token = storePersist.get('token') ?? "";
      return await axios.post(url+"/checkSession", { "token": token }).then((response)=> {
        return response?.data?.success;
      }).catch(()=> false);
    }
  }catch(error){
    return false;
  }
}