import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import storePersist
 from '../utils/storePersist';
 
const Logout = () => {
  const history = useNavigate();
  useEffect(() => {
    async function asyncLogout() {
      storePersist.clear();
      history('/login')
    }
    asyncLogout();
  });

  return <></>;
};
export default Logout;
